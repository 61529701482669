<script
  setup
  lang="ts"
  generic="TEntity extends Exclude<keyof GA4Entities, 'cartList' & 'gaItem'>"
>
import type { GA4Entities } from "nuxt-ga4"
import type { UseIntersectionObserverOptions } from "@vueuse/core"

const props = withDefaults(
  defineProps<{
    observerOptions?: UseIntersectionObserverOptions
    reRender?: boolean // It allows you to re-render the execution of this event WHENEVER the "statefulEntityItem?.value" re-evalutes (from "GAItem")
  }>(),
  { observerOptions: { threshold: 0.9 } }
)

const id = inject("id")
const statefulEntityList: GA4Entities[TEntity] | undefined =
  inject("statefulEntityList")
const statefulEntityItem: GA4Entities["gaItem"] | undefined =
  inject("statefulEntityItem")

const eventName = "view_item_list"
const eventKey = `event_${eventName}_${id}`
const event = "custom_event"

const containerRef = ref<HTMLElement>()
const gaEventRef = ref<any>()

const handleIntersectionObserver = () => {
  return useIntersectionObserver(
    containerRef,
    (entries, observer) => {
      for (let entry of entries) {
        if (entry.isIntersecting) {
          if (Object.keys(statefulEntityItem?.value ?? {}).length) {
            gaEventRef.value?.sendEvent({ clearEcommerce: false })
          }
          observer.disconnect()
        }
      }
    },
    props.observerOptions
  )
}

// MAIN EXECUTION
const { stop: stopMainExecution } = handleIntersectionObserver()
const previousReRenderExecution = ref<() => void>(() => null)

// RE-RENDER EXECUTION
if (props?.reRender) {
  watch(
    () => statefulEntityItem?.value,
    (newValue, oldValue) => {
      if (!isEqual(newValue, oldValue)) {
        // Whenever we re-render the GA execution, we must STOP these, to avoid repeated executions
        previousReRenderExecution.value() // Stop the PREVIOUS RE-RENDER
        stopMainExecution() // Stop the MAIN ONE

        const { stop: stopReRenderExecution } = handleIntersectionObserver()
        previousReRenderExecution.value = stopReRenderExecution
      }
    }
  )
}

const payload = computed(() => {
  // If the sort value is empty, null or undefined then use the default value 'Miglior risultato'
  // Jira ticket - https://lafarmacia.atlassian.net/browse/TF-1541
  const sortValue = statefulEntityList?.value?.sort
  const fallbackSortValue = "Miglior risultato"

  const finalSortValue = sortValue || fallbackSortValue

  //Remapping of the filters to send to GA, as asked here:  Jira ticket - https://lafarmacia.atlassian.net/browse/TF-1541
  type OriginalFilter = {
    type: "brand.name" | "mainCategory.name" | "price.amount_float"
    value: string
  }

  const typeMapping: Record<OriginalFilter["type"], string> = {
    "brand.name": "Brand",
    "mainCategory.name": "Category",
    "price.amount_float": "Price"
  }

  const remappedFilters: OriginalFilter[] = (
    statefulEntityList?.value?.filters ?? []
  ).map((filter: OriginalFilter) => ({
    ...filter,
    type: typeMapping[filter.type as OriginalFilter["type"]] || filter.type
  }))

  return {
    event,
    action: eventName,
    ecommerce: {
      items: statefulEntityItem?.value ?? []
    },
    item_list_name: statefulEntityList?.value?.list_name ?? "",
    filters: remappedFilters ?? [],
    sort_value: finalSortValue
  }
})
</script>

<template>
  <div ref="containerRef" class="h-full w-full">
    <GAEvent
      :event="event"
      :eventKey="eventKey"
      :payload="payload"
      :lazy="true"
      ref="gaEventRef"
    >
      <slot />
    </GAEvent>
  </div>
</template>
